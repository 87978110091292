import * as React from 'react'
import Layout from "../components/layout/layout";
import {graphql} from "gatsby";
import {Trans, useTranslation} from "react-i18next";
import {space, reservation} from "./reservation.module.scss";
import {I18nextContext} from "gatsby-plugin-react-i18next";

const ReservationPage = () => {
    const context = React.useContext(I18nextContext);
    const language = context.language;
    const {t} = useTranslation();
    return (
        <Layout pageTitle={t('pages.reservation.title')}>
            <div className={space}></div>
            <br/>
            <br/>
            <div className="text-center">
                <div className={"infotxt h3"}>
                    <Trans>pages.reservation.title</Trans>
                </div>
                <iframe title="reservation" src={"https://embed.bolerozlatibor.com/" + language} className={reservation}>
                    Your browser doesn't support iframes, contact us directly at info@bolerozlatibor.com for reservation.
                </iframe>
            </div>
        </Layout>
    )
}
export default ReservationPage

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
